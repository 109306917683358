<template>
  <pro-view-proposals :title="title" :tenderers="tenderers" />
</template>

<script>
import ProViewProposals from "@/components/PROSmart/ProViewProposals";

export default {
  components: {
    ProViewProposals,
  },
  data() {
    return {
      title: "Technical Folder – Assessment Version",
      tenderers: [],
    };
  },
  mounted() {
    this.$proSmart.tender
      .getTechnicalFolderAssessmentVersion(this, this.$route.params.id)
      .then((res) => {
        this.tenderers = res.tenderer.map((tenderer) => ({
          ref: tenderer.ref,
          name: tenderer.name,
          folders: tenderer.technicalFolder,
        }));
      });
  },
};
</script>
